.btn {
    padding: 12px 30px;
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    @include text (4.5vw, 4.5vw, 700);
    display: inline-block;
    @media only screen and (min-width:480px) {
        @include text (3.5vw, 3.5vw, 700);
    }
    @media only screen and (min-width:600px) {
        @include text (20px, 20px, 700);
    }
    &-primary {
        background-color: $medium-color;
    }
    &-secondary {
        background-color: $gold-color;
        @include text (3.6vw, 3.6vw, 700);
        padding: 10px 18px;
        @media only screen and (min-width:480px) {
            @include text (3vw, 3vw, 700);
        }
        @media only screen and (min-width:600px) {
            @include text (16px, 16px, 700);
        }
    }
    &-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
            margin-right: 8px;
        }
    }
}
button {
    border: none;
    box-shadow: none;
}
.form {
    &__group {
        margin-bottom: 15px;
    }
    input, textarea {
        border: 1px solid #D1BFAF;
        background-color: #F8F4EA;
        font-family: 'Montserrat', sans-serif;
        @include text (5vw, 5.5vw, 600);
        @media only screen and (min-width:600px) {
            @include text (18px, 18px, 400);
        }
        @media only screen and (min-width:1024px) {
            @include text (18px, 18px, 400);
        }
        @media only screen and (min-width:1400px) {
            
        }
    }
    label {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        @include text (5vw, 5.5vw, 600);
        text-transform: uppercase;
        color: $dark-color;
        @media only screen and (min-width:600px) {
            @include text (18px, 18px, 400);
        }
        @media only screen and (min-width:1024px) {
            @include text (18px, 18px, 400);
        }
        @media only screen and (min-width:1400px) {
            
        }
    }
    input {
        display: block;
        width: 100%;
        padding: 10px 10px;
        box-sizing: border-box
    }
    textarea {
        box-sizing: border-box;
        width: 100%;
        display: block;    
        padding: 10px;
        height: 70px; 
    }
    &__firm {
        label {
            @include text (4vw, 4.5vw, 600);
            @media only screen and (min-width:600px) {
                @include text (18px, 18px, 600);
            }
            @media only screen and (min-width:1024px) {
                @include text (18px, 18px, 600);
            }
            @media only screen and (min-width:1400px) {
                
            }
        }
        textarea, input {
            @include text (4vw, 4.5vw, 400);
        }
    }
}