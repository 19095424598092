html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
    scroll-behavior:smooth; 
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
   background-color: $body-color;
}
h1, h2, h3, h4, h5, h5 {
    font-family: $font-titles;
    color: $medium-color;
}
h1 {
    font-size: 10vw;
}
body {
    font-family: $font-normal;
}
img {
    max-width: 100%;
    height: auto;
}
.header {
    position: relative;
    padding-bottom: 16%;
    &__bg {
        position: relative;
        z-index: 1;
    }
    &__image {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%,0);
        max-width: 75%;
    }
}
.buttons {
    padding: 15px 30px 0 30px;
    text-align: center;
    &__group {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        .btn {
            margin: 0 5px;
        }
    }
    
    .btn {
        margin-bottom: 15px;
        &:last-child {
            //margin-bottom: 0;
        }
    }
}
.content {
    padding: 0;
    margin: 20px 15px;
    border: 1px dotted $clear-color;
    position: relative;
    &__int {
        border: 1px solid $clear-color;
        padding: 10px 10px 40px 10px;
        margin: 5px;
        .btn {
            margin: 30px 0 0 0;
            display: flex;
            align-items: center;
            @media only screen and (min-width:480px) {
                display: inline-block;
            }
        }
    }
    &:after, &:before {
        content: '';
        position: absolute;
        background-size: cover;
        width: 50px;
        height: 50px;
        position: absolute;
    }
    &:after {
        background: url(../images/flowers-top.svg) center center no-repeat;
        top: -17px;
        left: -10px;
    }
    &:before {
        background: url(../images/flowers-bottom.svg) center center no-repeat;
        right: -10px;
        bottom: -20px;
    }
    .title {
        @include text (11vw, 10.5vw, 400);
        text-align: center;
        margin: 15px 0;
        @media only screen and (min-width:600px) {
            @include text (50px, 50px, 400);
        }
        @media only screen and (min-width:1024px) {
            @include text (70px, 70px, 400);
        }
        @media only screen and (min-width:1400px) {
            
        }
    }
    .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        @media only screen and (min-width:768px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media only screen and (min-width:1024px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media only screen and (min-width:1400px) {
            grid-template-columns: repeat(5, 1fr);
        }
        
        &__item {
            text-align: center;
            //background: url(../images/polaroid.svg) no-repeat center center;
            background-size: cover;
            padding: 12%;
            background-color: #fff;
            padding: 6px 6px 15px 6px;
            position: relative;
            -webkit-box-shadow: 2px 2px 5px 0px rgba(158,158,158,1);
            -moz-box-shadow: 2px 2px 5px 0px rgba(158,158,158,1);
            box-shadow: 2px 2px 5px 0px rgba(158,158,158,1);
            .user {
                @include text (12px, 12px, 400);
                color: $gold-color;
                position: absolute;
                right: 6px;
                bottom: 3px;
            }
        }
    }
    .firm {
        border-bottom: 1px solid $clear-color;
        position: relative;
        padding: 40px 0 30px 0;
        &:first-child {
            padding-top: 0 !important;
        }
        &:before {
            position: absolute;
            content: '';
            bottom: -37px;
            left: 50%;
            width: 80px;
            height: 80px;
            background: url(../../assets/images/flowers-bottom.svg) no-repeat center center;
            background-size: cover;
            transform: translate(-50%, 0)
        }
        &__text {
            @include text (7vw, 7vw, 400);
            font-family: 'Caveat', cursive;
            color: $medium-color;
            margin-bottom: 15px;
            @media only screen and (min-width:768px) {
                @include text (22px, 26px, 400);
            }
            @media only screen and (min-width:1024px) {
                @include text (22px, 26px, 400);
            }
            @media only screen and (min-width:1400px) {
                
            }
        }
        &__signature {
            @include text (6vw, 6.5vw, 400);
            font-family: 'Caveat', cursive;
            color: $gold-color;
            text-align: right;
            margin-bottom: 15px;
            @media only screen and (min-width:768px) {
                @include text (18px, 22px, 400);
            }
            @media only screen and (min-width:1024px) {
                @include text (18px, 22px, 400);
            }
            @media only screen and (min-width:1400px) {
                
            }

        }
    }
}
.wrapper {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (min-width:768px) {
        max-width: 768px;
        margin: 0 auto;
    }
    .top-button {
        position: fixed;
        bottom: 8px;
        right: 8px;
        z-index: 100;
        width: 40px;
        height: 40px;
    }
}
.login {
    .header__bg {
        display: none !important;
    }
    .header {
        padding-bottom: 0;
    }
}